import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';

import Card from 'components/common/cards/Card';
import Subtitle from 'components/common/Subtitle';
import Spinner from 'components/common/Spinner';

import useInterval from 'hooks/useInterval';
import getNested from 'utility/getNested';
import {useApp} from 'components/providers/AppProvider';
import colors from 'utility/colors';

const ModalLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.MODAL_BACKGROUND};
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
`

const GlobalLoading = () => {
  const {load} = useApp();
  const [messageIndex, setMessageIndex] = useState(0)

  const isLoading = getNested(load, 'loading')
  const messages = getNested(load, 'messages')
  const transitionSpeed = getNested(load, 'transitionSpeed') || 5000

  useInterval(() => {
    if(messageIndex === (messages.length - 1)){
      setMessageIndex(0)
    }else{
      setMessageIndex(messageIndex + 1)
    }
  }, transitionSpeed);

  if(!isLoading)
    return null

  let loadingMessage = messages && messages.length && messageIndex >= 0 && messages[messageIndex]

  return <ModalLoader>
    <Container>
      <Row>
        <Col xs={{span: 10, offset: 1}} md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}>
          <Card style={{borderRadius: 5, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Spinner size={60} type={'Grid'} style={{marginTop: 30}}/>
            <Subtitle style={{textAlign: 'center', marginTop: 30, marginBottom: 20}}>{loadingMessage}</Subtitle>
          </Card>
        </Col>
      </Row>
    </Container>
  </ModalLoader>
}

export default GlobalLoading