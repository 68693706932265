import LogRocket from 'logrocket'
import versions from 'utility/versions'
import environment from 'utility/environment'
import isBrowser from 'utility/isBrowser'
import * as crisp from 'utility/crisp'

const isProductionAndBrowser = environment.production && isBrowser

export const init = () => {
  if (!isProductionAndBrowser) return null

  const logRocketPrimaryId = 'q9ttul/home-tax-shield'
  // const logRocketSecondaryId = 'hts/home-tax-shield'

  LogRocket.init(logRocketPrimaryId, {
    mergeIframes: true,
    release: versions.app,
  })
}

export const track = (name) => {
  if (!isProductionAndBrowser) return null

  LogRocket.track(name)
}

export const identify = (key, values) => {
  if (!key || !values || !isProductionAndBrowser) return null

  LogRocket.identify(key, { ...values })
}

export const integrateWithGa = () => {
  if (!isProductionAndBrowser) return null

  LogRocket.getSessionURL(function (sessionURL) {
    window &&
      window.gtag &&
      typeof window.gtag === 'function' &&
      window.gtag('event', 'LogRocket', {
        LogRocketURL: sessionURL,
        eventCategory: 'LogRocket',
        eventAction: sessionURL,
      })
  })
}

export const integrateWithCrisp = () => {
  if (!isProductionAndBrowser) return null

  LogRocket.getSessionURL(function (sessionURL) {
    crisp.customData('logrocketUrl', sessionURL)
  })
}

export const getSessionURL = () => {
  if (!isProductionAndBrowser) return null

  return LogRocket.sessionURL
}

export const captureException = ({
  err = 'Unknwon Error',
  tags = {},
  extra = {},
}) => {
  if (!isProductionAndBrowser) return null

  LogRocket.captureException(err, {
    tags: { ...tags },
    extra: { ...extra },
  })
}

export const captureMessage = ({
  messsage = 'Unknwon Error',
  tags = {},
  extra = {},
}) => {
  if (!isProductionAndBrowser) return null

  LogRocket.captureMessage(messsage, {
    tags: { ...tags },
    extra: { ...extra },
  })
}
