import axios from 'utility/axios'
import {
	POST_PAY_INVOICE,
	POST_PAY_ACH_FAILED_INVOICE,
	GET_GROUP_INVOICES,
	GET_INVOICE_HISTORY,
	POST_PAY_RENEWAL,
} from 'utility/endpoints'
import getNested from 'utility/getNested'

export const fetchInvoiceHistory = async ({ groupId, tenant = 'hometaxshield' }) => {
	if (!groupId || groupId === '[groupId]') return null
	try {
		const res = await axios.get(`${GET_INVOICE_HISTORY}${groupId}/${tenant}`)
		return res?.data
	} catch (err) {
		console.log('Error: ', err)
	}
}

export const fetchInvoicesFromGroup = async ({ groupId, year, tenant = 'hometaxshield' }) => {
	if (!groupId || groupId === '[slug]') return null

	try {
		let url = `${GET_GROUP_INVOICES}/${groupId}${year ? `/${year}` : ''}/${tenant}`
		const res = await axios.get(url)
		const groupInvoiceData = getNested(res, 'data')
		return {
			...groupInvoiceData,
			invoices: getNested(groupInvoiceData, 'invoices'),
		}
	} catch (err) {
		console.log('Error: ', err)
		throw err
	}
}

export const payInvoice = async ({
	invoiceAmount,
	invoiceId,
	paymentMethod,
	tenant = 'hometaxshield',
}) => {
	try {
		const res = await axios.post(POST_PAY_INVOICE, {
			invoiceAmountInCents: invoiceAmount,
			invoiceId,
			paymentMethod,
			tenantCode: tenant,
		})
		return res
	} catch (err) {
		console.log('Error: ', err)
		throw err
	}
}

export const payAchInvoice = async ({
	invoiceAmount,
	invoiceId,
	paymentMethod,
	tenant = 'hometaxshield',
}) => {
	try {
		const res = await axios.post(POST_PAY_ACH_FAILED_INVOICE, {
			invoiceAmountInCents: invoiceAmount,
			invoiceId,
			paymentMethod,
			tenantCode: tenant,
		})
		return res
	} catch (err) {
		console.log('Error: ', err)
		throw err
	}
}

export const payRenewal = async ({
	amountInCents,
	groupId,
	paymentMethodId,
	tenant = 'hometaxshield',
}) => {
	try {
		const res = await axios.post(POST_PAY_RENEWAL, {
			amountInCents,
			groupId,
			paymentMethodId,
			taxYear: '2022',
			tenantCode: tenant,
		})
		return res
	} catch (err) {
		console.log('Error: ', err)
		throw err
	}
}
