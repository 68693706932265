import axios from 'utility/axios'
import {
  GET_GROUP_DETAIL,
  POST_GROUP_DEFAULT_PAYMENT,
  GET_GROUP_PAYMENT_METHODS,
} from 'utility/endpoints'
import getNested from 'utility/getNested'
import {
  formatPropertyMetaStatus,
  formatPropertySignupStatus,
} from 'utility/property'

export const fetchGroupById = async ({ id, year }) => {
  if (!id || id === '[slug]') return null

  try {
    let url = `${GET_GROUP_DETAIL}/${id}${year ? `/${year}` : ''}/hometaxshield`
    const res = await axios.get(url)
    const groupPortfolioData = getNested(res, 'data')

    return {
      ...groupPortfolioData,
      properties:
        groupPortfolioData &&
        groupPortfolioData.properties &&
        groupPortfolioData.properties.length
          ? groupPortfolioData.properties.map((property) => {
              return {
                id: getNested(property, 'propertyTag'),
                htsGroup: property,
                meta: {
                  signupState: formatPropertySignupStatus({ data: property }),
                  status: formatPropertyMetaStatus({ data: property }),
                },
              }
            })
          : [],
    }
  } catch (err) {
    console.log('Error: ', err)
    throw err
  }
}

export const getGroupPaymentMethods = async ({
  id,
  tenantCode = 'hometaxshield',
}) => {
  try {
    const url = `${GET_GROUP_PAYMENT_METHODS}/${id}/${tenantCode}`
    const res = await axios.get(url)
    const groupData = getNested(res, 'data')
    return groupData
  } catch (err) {
    console.error('getGroupPaymentMethods Error: ', err)
    return err
  }
}

export const updateDefaultPayment = async ({
  id,
  paymentMethod,
  tenantCode = 'hometaxshield',
}) => {
  try {
    const res = await axios.post(POST_GROUP_DEFAULT_PAYMENT, {
      groupSlugId: id,
      paymentMethod,
      tenantCode,
    })
    if (res?.status === 200) return true

    return false
  } catch (err) {
    console.error('updateDefaultPayment Error: ', err)
    throw err
  }
}

export const fetchGroup = async ({ id, year }) => {
  const groupData = await fetchGroupById({ id, year })

  return groupData
}
