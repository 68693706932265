import axios from 'utility/axios';
import getNested from 'utility/getNested';

export const types = {
  SET_PROPERTY: 'SET_PROPERTY',
};

export const clearPropertyDetails = async ({dispatch})=> {
  dispatch({
    type: types.SET_PROPERTY,
    payload: {
      property: {},
    },
  });
}

export const getPropertyDetails = async ({dispatch, id}) => {
  try{
    // const url = `http://localhost:3001/property/${id}`;
    const url = `http://localhost:3001/property`
    const response = await axios.get(url);
    const property = getNested(response, 'data', 'property')

    dispatch({
      type: types.SET_PROPERTY,
      payload: {
        property,
      },
    });
  }catch(err){
    console.log("Error getting property", err)
  }
}
