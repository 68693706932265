import React, { useContext, useState, useEffect } from 'react'
import * as tenantQuery from 'utility/queries/tenant'
import { defaultObjData } from 'utility/useStateDefaults'

const TenantContext = React.createContext(null)

const TenantProvider = ({ children }) => {
  const [ready, setReady] = useState(false)
  const [settings, setSettings] = useState(defaultObjData)

  useEffect(() => {
    fetchSettings({})
  }, [])

  useEffect(() => {
    if (settings?.currentYear) {
      setReady(true)
    }
  }, [settings])

  const fetchSettings = async ({ tenant = 'hometaxshield' }) => {
    const tenantSettings = await tenantQuery.fetchSettings({
      tenant,
    })
    setSettings(tenantSettings)
  }

  if (!ready) return null

  return (
    <TenantContext.Provider
      value={{
        fetchSettings,
        ready,
        settings,
      }}
    >
      {children}
    </TenantContext.Provider>
  )
}

const useTenant = () => {
  const tenant = useContext(TenantContext)
  if (tenant == null) {
    throw new Error('useTenant() called outside of a TenantProvider?')
  }
  return tenant
}

export { TenantProvider, useTenant }
