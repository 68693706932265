import axios from 'axios';

let baseURL;
// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//   baseURL = "http://localhost:3000";
// } else {
// }
baseURL = 'https://wp.hometaxshield.com/wp-json/wp/v2'// 'process.env.SERVER_BASE_URL';

const Axios = axios.create({
  baseURL: baseURL,
  timeout: 50000
});

export default Axios;
