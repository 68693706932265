import { services } from './userServices';

export const types = {
  UPDATE_USER: 'UPDATE_USER',
  CHANGE_FORM_INDEX: 'CHANGE_FORM_INDEX',
  ADD_USER_PROPERTY: 'ADD_USER_PROPERTY',
  REMOVE_USER_PROPERTY: 'REMOVE_USER_PROPERTY',
  EDIT_USER_PROPERTY: 'EDIT_USER_PROPERTY',
  MAKE_PROPERTY_PRIMARY: 'MAKE_PROPERTY_PRIMARY',
  TOGGLE_FORM_SHOULD_SUBMIT: 'TOGGLE_FORM_SHOULD_SUBMIT',
  TOGGLE_LOADING_STATE: 'TOGGLE_LOADING_STATE',
  SET_LOADING_STATE: 'SET_LOADING_STATE'
};

export const actions = {

  async updateUser({dispatch, data}){
    dispatch({
      type: types.UPDATE_USER,
      payload: {
        ...data
      }
    })
  },

  async changeFormIndex(dispatch, index) {
    dispatch({
      type: types.CHANGE_FORM_INDEX,
      payload: {
        index,
      },
    });
  },
  // Hit the API to search for properties
  async searchForProperties(dispatch, searchText, selectedProperties) {
    try {
      // Only search if their are more than three characters in the search string
      const newSearchText = searchText.trim();
      const response = await services.getProperties(newSearchText);
      const filteredResponse = response && response.filter((property) => {
        for (let i = 0; i < selectedProperties.length; i += 1) {
          if (selectedProperties[i].countyNumber === property.countyNumber) {
            return false;
          }
        }
        return true;
      });
      console.log('Here is what was sent', 'response', response, 'filtered response', filteredResponse);
      dispatch({
        type: types.UPDATE_USER,
        payload: {
          properties: filteredResponse,
        },
      });
    } catch (err) {
      console.log(err);
    }
  },
  /**
   * Add a selected property to the state for the user
   * @param {*} dispatch
   * @param {*} property
   */
  async addProperty(dispatch, property) {
    dispatch({
      type: types.ADD_USER_PROPERTY,
      payload: {
        property,
      },
    });
  },
  /**
   * Remove a selected property to the state for the user
   * @param {*} dispatch
   * @param {*} property
   */
  async removeProperty(dispatch, property) {
    dispatch({
      type: types.REMOVE_USER_PROPERTY,
      payload: {
        property,
      },
    });
  },
  async editProperty(dispatch, property) {
    dispatch({
      type: types.EDIT_USER_PROPERTY,
      payload: {
        property,
      },
    });
  },
  /**
   * Make a property the primary selected property
   * @param {*} dispatch
   * @param {*} property
   */
  async makePropertyPrimary(dispatch, property) {
    dispatch({
      type: types.MAKE_PROPERTY_PRIMARY,
      payload: {
        property,
      },
    });
  },
  /**
   * Submit the user registration form.
   * First and last name need to be combined
   * @param {*} dispatch
   * @param {*} param1
   */
  async submitUserForm(dispatch, {
    fullName,
    email,
    phone,
    properties,
    fbuy_ref_code,
  }) {
    console.log('Did the ref code pass to action', fbuy_ref_code);
    const response = await services.submitUserForm({
      fullName, email, phone, properties, fbuy_ref_code,
    });

    dispatch({
      type: types.UPDATE_USER,
      payload: {
        ...response,
      },
    });

    return response;
  },
  async checkDocusignEnvelopeStatus(dispatch, docusignId) {
    // Hit the API to get the envelope status
    const response = await services.checkDocusignEnvelopeStatus(docusignId);

    await dispatch({
      type: types.UPDATE_USER,
      payload: {
        docusignEnvelopeStatus: response,
      },
    });

    return response;
  },
  async submitStripePayment(dispatch, paymentMethod, aofAReferenceId, fbuy_ref_code) {
    console.log('Test', aofAReferenceId);
    const response = await services.submitStripePayment(paymentMethod, aofAReferenceId, fbuy_ref_code);

    return response;
  },
  async toggleFormShouldSubmit(dispatch) {
    dispatch({
      type: types.TOGGLE_FORM_SHOULD_SUBMIT,
    });
  },
  async clearProperties(dispatch) {
    dispatch({
      type: types.UPDATE_USER,
      payload: {
        properties: []
      }
    })
  },
  async toggleLoadingState(dispatch) {
    dispatch({
      type: types.TOGGLE_LOADING_STATE,
    });
  },
  async saveFriendBuyInfo(dispatch, info) {
    console.log('Action Payload for friend buy', info);
    dispatch({
      type: types.UPDATE_USER,
      payload: {...info},
    });
  },
};
