import { useEffect, useState } from 'react'

import { loadState, saveState } from 'utility/localStorage'

export const useLocalStorage = (key, defaultValue) => {
  const [isHydrated, setIsHydrated] = useState(false)
  const [value, setValueClassic] = useState(defaultValue)

  const setValue = (newValue) => {
    saveState(key, newValue)
    setValueClassic(newValue)
  }

  useEffect(() => {
    const hydrate = async () => {
      setValueClassic(loadState(key))
      setIsHydrated(true)
    }
    hydrate()
  }, [key])

  return [value, setValue, isHydrated]
}
