import { types } from './documentsActions';

export const documentsState = []

const documentsReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.SET_DOCUMENTS:
      return payload.documents;

    default:
      return state;
  }
}

export default documentsReducer;