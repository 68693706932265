import React, { useContext, useState, useEffect } from 'react'
import * as blogQuery from 'utility/queries/blog'

const BlogContext = React.createContext(null)

const BlogProvider = ({ children }) => {
  const [contentCalendar, setContentCalendar] = useState([])
  const [categories, setCategories] = useState([])
  const [posts, setPosts] = useState([])
  const [post, setPost] = useState(null)
  const [questions, setQuestions] = useState([])

  const fetchPosts = async () => {
    const _posts = await blogQuery.fetchPosts()
    setPosts(_posts)
  }

  const fetchPostBySlug = async (slug) => {
    const _foundPost = await blogQuery.fetchPostBySlug(slug)
    setPost(_foundPost)
  }

  const fetchCategories = async () => {
    const _categories = await blogQuery.fetchCategories()
    setCategories(_categories)
  }

  const fetchQuestions = async () => {
    const _questions = await blogQuery.fetchQuestions()
    setQuestions(_questions)
  }

  const fetchContentCalendar = async () => {
    const _contentCalendar = await blogQuery.fetchContentCalendar()
    setContentCalendar(_contentCalendar)
  }

  useEffect(() => {
    fetchPosts()
    fetchCategories()
    fetchQuestions()
  }, [])

  return (
    <BlogContext.Provider
      value={{
        fetchPosts,
        fetchCategories,
        fetchQuestions,
        fetchPostBySlug,
        fetchContentCalendar,
        setPost,
        post,
        posts,
        categories,
        questions,
        contentCalendar,
      }}
    >
      {children}
    </BlogContext.Provider>
  )
}

const useBlog = () => {
  const auth = useContext(BlogContext)
  if (auth == null) {
    throw new Error('useBlog() called outside of a BlogProvider?')
  }
  return auth
}

export { BlogProvider, useBlog }
