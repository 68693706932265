// Import in individual reducers here
import {loadState, saveState} from './localStorage';
import environment from 'utility/environment';

import userReducer, { userState } from './user/userReducer';
import propertiesReducer, {propertiesState} from './properties/propertiesReducer';
import documentsReducer, {documentsState} from './documents/documentsReducer';
import receiptsReducer, {receiptsState} from './receipts/receiptsReducer';
import propertyReducer, {propertyState} from './property/propertyReducer';

// Destructure state object here
export const mainReducer = (state, action) => {
  const nextState = {
    user: userReducer(state.user, action),
    properties: propertiesReducer(state.properties, action),
    documents: documentsReducer(state.documents, action),
    receipts: receiptsReducer(state.receipts, action),
    property: propertyReducer(state.property, action)
  }
  
  if(environment.development || environment.review){
    console.log("================")
    console.log("Prev State: ", state)
    console.log(`Action: ${action.type}`, action)
    console.log("Next State: ", nextState)
    console.log("================")
  }

  saveState(nextState);
  return nextState;
};

// State pulled from the local storage
const persistedState = loadState();

// The state the first time a user loads the app
const baseState = {
  user: userState,
  properties: propertiesState,
  documents: documentsState,
  receipts: receiptsState,
  property: propertyState
}

// If theres something in the saved state, load that in, otherwise use the base state
export const initialState = persistedState === undefined ? 
  baseState : persistedState
