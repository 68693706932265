import React from 'react';
import styled from 'styled-components';

const H3 = styled.h3`
  font-family: 'Rubik', sans-serif;
  font-size: 22px;
`;

const Subtitle = ({children, align='left', style}) => {
  return <H3 style={{ marginBottom: 5, textAlign: align, ...style}}>{children}</H3>
}

export default Subtitle