import { types } from './receiptsActions';

export const receiptsState = []

const receiptsReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.SET_RECEIPTS:
      return payload.receipts;

    default:
      return state
  }
}

export default receiptsReducer