import React from 'react';
import styled from 'styled-components';
import Title from 'components/common/Title';

const StyledCardTitle = styled.h3`
  font-size: ${props => props.size}px;
  font-weight: bold;
  overflow: hidden;
  padding-bottom: 1px;
  text-align: ${(props) => props.align};
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.lines}; /* number of lines to show */
  -webkit-box-orient: vertical;
`
const CardTitle = ({children, align='left', size=24, style, lines=1}) => {
  return <StyledCardTitle align={align} size={size} style={style}>{children}</StyledCardTitle>
}

export default CardTitle;