import isBrowser from 'utility/isBrowser';

export const loadState = () => {
  try{
    const serializedState = isBrowser && localStorage.getItem('htsState');
    if (serializedState === null){
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch(err){
    console.log("ERROR LOADING STATE: ", err);
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    isBrowser && localStorage.setItem('htsState', serializedState);
  }catch(err){
    console.log("ERROR SAVING STATE: ", err);
    console.log("STATE: ", state);
  }
}
