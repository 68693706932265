import React, { useContext, useState, useEffect } from 'react'
import isBrowser from 'utility/isBrowser'
import getBreakpoints from 'utility/breakpoints'
import { useLocalStorage } from 'hooks/useLocalStorage'

const AppContext = React.createContext(null)

const AppProvider = ({ children }) => {
  const [authToken, setAuthToken, hydrated] = useLocalStorage('authToken', '')

  useEffect(() => {
    if (!authToken) console.log('No Auth Token :)')
  }, [authToken])

  const [width, setWidth] = useState(0)
  const [load, setLoad] = useState({
    loading: false,
    transitionSpeed: 1000,
    messages: ['Loading', 'Loading.', 'Loading..', 'Loading...'],
  })
  const [breakpoints, setBreakpoints] = useState({})

  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  const updateDimensions = () => {
    const body = isBrowser && document.getElementsByTagName('body')[0]
    const width =
      isBrowser &&
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        body.clientWidth)
    const _breakpoints = getBreakpoints(width)
    setWidth(width)
    setBreakpoints(_breakpoints)
  }

  const setLoading = (value) => {
    setLoad({ ...load, loading: value })
  }

  if (!hydrated) return null

  return (
    <AppContext.Provider
      value={{
        width,
        breakpoints,

        load,
        setLoad,
        setLoading,

        authToken,
        setAuthToken,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

const useApp = () => {
  const auth = useContext(AppContext)
  if (auth == null) {
    throw new Error('useApp() called outside of a AppProvider?')
  }
  return auth
}

export { AppProvider, useApp }
