import axios from 'utility/axios'
import { toSlug } from 'utility/string'
import { GET_TENANT_SETTINGS } from 'utility/endpoints'
import getNested from 'utility/getNested'

export const fetchSettings = async ({ tenant = 'hometaxshield' }) => {
  try {
    let url = `${GET_TENANT_SETTINGS}${toSlug(tenant)}`
    const res = await axios.get(url)
    return getNested(res, 'data')
  } catch (err) {
    console.log('Error: ', err)
  }
}
