/* eslint-disable indent */
import {filter} from 'underscore';
import parser from 'parse-address';
import { types, actions } from './userActions';

export const userState = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  properties: [],
  auth: false,
  selectedProperties: [],
  currentFormIndex: 0,
  visitedFormIndices: [],
  aOfAReferenceId: '',
  docusignRecipientViewUrl: '',
  errorMessage: '',
  docusignEnvelopeStatus: '',
  formShouldSubmit: false,
  loading: false,
};

const userReducer = function reducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case types.CHANGE_FORM_INDEX:
      // If this index has not been visited before than add it to the array of visited indices
      const newVisitedFormIndices = [...state.visitedFormIndices];

      if (userState.visitedFormIndices.indexOf(payload.index) === -1) {
        newVisitedFormIndices.push(payload.index);
      }

      return {
        ...state,
        currentFormIndex: payload.index,
        visitedFormIndices: newVisitedFormIndices,
      };
      
    case types.UPDATE_USER:
      const nextState = {
        ...state,
        ...payload
      }
      return nextState

    case types.ADD_USER_PROPERTY:
      const {selectedProperties} = state;
      const newProperty = payload.property;

      // Make sure the property being added is unique & not duplicated
      const alreadyExists = selectedProperties.findIndex((_property) => _property.searchId === newProperty.searchId)
      if(alreadyExists >= 0){
        return state
      }

      selectedProperties.push(newProperty);
      return {
        ...state,
      };
    
    case types.EDIT_USER_PROPERTY: 
      let { property } = action.payload;
      if(property.fullAddress){
        const parsedAddress = parser.parseLocation(property.fullAddress)
        property.ownerAddress = `${parsedAddress.number ? parsedAddress.number : ''} ${parsedAddress.prefix ? parsedAddress.prefix : ''} ${parsedAddress.street ? parsedAddress.street : ''} ${parsedAddress.type ? parsedAddress.type : ''}`.replace(/\s\s+/g, ' ').trim()
        property.ownerCity = parsedAddress.city
        property.ownerState = parsedAddress.state
        property.ownerZipCode = parsedAddress.zip
      }

      return {
        ...state,
        selectedProperties: state.selectedProperties.map(_property => {
          if(property.searchId === _property.searchId)
              return property
          return _property
        })
      }

    case types.MAKE_PROPERTY_PRIMARY:
      const updatedSelectedProperties = state.selectedProperties;
      // Set the isPrimary to false on the old property and true on the new property
      for (let i = 0; i < updatedSelectedProperties.length; i += 1) {
        if (updatedSelectedProperties[i].isPrimary && payload.property.countyNumber !== updatedSelectedProperties[i].countyNumber) {
          updatedSelectedProperties[i].isPrimary = false;
        }

        if (payload.property.countyNumber === updatedSelectedProperties[i].countyNumber) {
          updatedSelectedProperties[i].isPrimary = true;
        }
      }
      return {
          ...state,
          selectedProperties: updatedSelectedProperties,
      };

    case types.REMOVE_USER_PROPERTY:
      // Remove the property
      const newSelectedProperties = filter(state.selectedProperties, (prop) => {
        if (prop) {
          return (prop.countyNumber !== payload.property.countyNumber);
        }

        return false;
      });

      // Figure out if there is a primary property, if there isn't set one
      let isThereAPrimaryProperty = false;
      for (let i = 0; i < newSelectedProperties.length; i += 1) {
        if (newSelectedProperties[i].isPrimary) {
          isThereAPrimaryProperty = true;
        }
      }

      if (!isThereAPrimaryProperty) {
        if (newSelectedProperties.length !== 0 && newSelectedProperties[0]) {
          newSelectedProperties[0].isPrimary = true;
        }
      }

      return {
        ...state,
        selectedProperties: newSelectedProperties,
      };

    case types.TOGGLE_FORM_SHOULD_SUBMIT:
      return {
        ...state,
        formShouldSubmit: !state.formShouldSubmit,
      };

    case types.SET_LOADING_STATE:
      let { loading } = action.payload;
      return {
        ...state,
        loading,
      };
      
    case types.TOGGLE_LOADING_STATE:
      return {
        ...state,
        loading: !state.loading,
      };
      
    default:
      return { ...state };
  }
}

export default userReducer;