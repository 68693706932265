import getNested from 'utility/getNested'
import moment from 'moment'

export const formatPropertySignupStatus = ({ data, deadline }) => {
	const isProtected = getNested(data, 'isProtected') === 'Yes' ? true : false
	const isBlocked = getNested(data, 'isGroupBlocked') === 'Yes' ? true : false

	const currentDate = moment()
	const deadlineDate = moment(deadline)

	const displayContactUs = isBlocked
	const isBeforeDeadline = deadline ? currentDate < deadlineDate : true
	const status = data?.propertyTaxYear?.appealStatus

	// Can canel if status === pending or ready to file AND the tenants settings years are equal
	const canCancel = status === 'Pending' || status === 'Ready to File'

	return {
		isProtected,
		signedUpAndProtected: isProtected,
		contactRequired: displayContactUs,
		isBeforeDeadline: isBeforeDeadline,
		canSignup: !isProtected && isBeforeDeadline,
		missedDeadline: !isProtected && !isBeforeDeadline,
		canCancel,
	}
}

export const formatPropertyMetaStatus = ({ data }) => {
	const isProtected = getNested(data, 'isProtected') === 'Yes' ? true : false

	const status = getNested(data, 'propertyTaxYear', 'appealStatus')

	const isPending = status === 'Pending' ? true : false
	const isReady = status === 'Ready to File' ? true : false
	const isFiled = status === 'Filed' ? true : false
	const isStartedNegotiations = status === 'Started Negotiations' ? true : false
	const isInArbitration = status === 'In Arbitration' ? true : false
	const isInLitigation = status === 'In Litigation' ? true : false
	const isFinalizingNegotiations = status === 'Finalizing Negotiations' ? true : false
	const isComplete = status === 'Completed'

	const isWithdrawn = status === 'Withdrawn' ? true : false
	const isCanceled = status === 'Withdrawn' || status === 'Not Protested'

	const calculateStatusPercentage = () => {
		let percent = 20
		if (!isProtected || isPending) {
			percent = 0
		} else if (isReady) { 
			percent = 20
		} else if (isFiled) {
			percent = 40
		} else if (isStartedNegotiations) {
			percent = 60
		} else if (isFinalizingNegotiations || isInArbitration || isInLitigation) {
			percent = 85
		} else if (status === 'Completed') {
			percent = 100
		}
		return percent
	}

	let readable
	if (isComplete) {
		readable = 'Completed'
	} else if (isPending) {
		readable = 'Pending Renewal'
	} else if (isReady) {
		readable = 'Ready to File'
	} else if (isFiled) {
		readable = 'Filed'
	} else if (isInArbitration) {
		readable = 'In Arbitration'
	} else if (isInLitigation) {
		readable = 'In Litigation'
	} else if (isStartedNegotiations) {
		readable = 'Started Negotiations'
	} else if (isFinalizingNegotiations) {
		readable = 'Finalizing Negotiations'
	} else if (isCanceled) {
		readable = 'Cancelled'
	} else {
		readable = 'In Process'
	}
	
	return {
		percent: calculateStatusPercentage(),
		pending: isPending,
		ready: isReady,
		filed: isFiled,
		arbitration: isInArbitration,
		startedNegotiations: isStartedNegotiations,
		finalizingNegotiations: isFinalizingNegotiations,
		complete: isComplete,
		withdrawn: isWithdrawn,
		canceled: isCanceled,
		notProtested: status === 'Not Protested' ? true : false,
		readable,
	}
}
