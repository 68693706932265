import axios from 'axios'
import { loadState } from 'utility/localStorage'

let baseURL = '/'
const Axios = axios.create({
  baseURL,
  timeout: 100000,
  crossdomain: true,
  withCredentials: false,
})

Axios.interceptors.request.use((config) => {
  const authToken = loadState('authToken')

  if (authToken) {
    config.headers['Authorization'] = `Bearer ${authToken}`
  }
  return config
})

export default Axios
