import { types } from './propertiesActions';

export const propertiesState = []

const propertiesReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.SET_PROPERTIES:
      return payload.properties;

    default:
      return state;
  }
}

export default propertiesReducer;