import React from 'react';
import Loader from "react-loader-spinner";
import colors from 'utility/colors';

const Spinner = ({
  type='Oval',
  color=colors.ACCENT,
  height=20,
  width=20,
  size,
  style={},
  ...props
}) => {
  if(size){
    width = size;
    height=size;
  }
  return <div style={{display: 'inline-block', ...style}}>
    <Loader
      type={type}
      color={color}
      height={height}
      width={width}
      {...props}
    />
  </div>
}

export default Spinner
