import axios from 'utility/axios';
import getNested from 'utility/getNested';

export const types = {
  SET_RECEIPTS: 'SET_RECEIPTS',
};

export const getReceipts = async (dispatch) => {
  try{
    const url = 'http://localhost:3001/receipts';
    const response = await axios.get(url);
    const receipts = getNested(response, 'data', 'receipts')
    dispatch({
      type: types.SET_RECEIPTS,
      payload: {
        receipts,
      },
    });
  }catch(err){
    console.log("Error getting receipts", err)
  }
}
