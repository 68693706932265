import axios from 'utility/axios';
import getNested from 'utility/getNested';

export const types = {
  SET_DOCUMENTS: 'SET_DOCUMENTS',
};

export const getDocuments = async (dispatch) => {
  try{
    const url = 'http://localhost:3001/documents';
    const response = await axios.get(url);
    const documents = getNested(response, 'data', 'documents')
    dispatch({
      type: types.SET_DOCUMENTS,
      payload: {
        documents,
      },
    });
  }catch(err){
    console.log("Error getting documents", err)
  }
}
