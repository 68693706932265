import isBrowser from 'utility/isBrowser';
import isProductionAndBrowser from 'utility/isProductionAndBrowser'

export const identify=(key, index)=>{
  if(!isProductionAndBrowser || !key || !index)
    return true

  const crisp = isBrowser && window.$crisp || [];
  crisp.push(['set', `user:${key}`, index]);
}

export const customData=(key, value)=>{
  if(!isProductionAndBrowser || !key || !value)
    return true
  const crisp = isBrowser && window.$crisp || [];
  crisp.push(['set', 'session:data', [key, value]]);
}

export const customEvent=(data) => {
  if(!isProductionAndBrowser || !data)
    return true

  const crisp = isBrowser && window.$crisp || [];
  crisp.push(["set", "session:event", [data]])
}

export const sendMessage = (message) => {
  if(!isProductionAndBrowser || !message)
    return true

  const crisp = isBrowser && window.$crisp || [];
  crisp.push(["do", "message:send", ["text", message]])
}

export const hideChat=()=>{
  if(!isProductionAndBrowser)
    return true
    
  const crisp = isBrowser && window.$crisp || [];
  crisp.push(["do", "chat:hide"])
}

export const openChat = () => {
  if(!isProductionAndBrowser)
    return true
  
  const crisp = isBrowser && window.$crisp || [];
  crisp.push(["do", "chat:open"])
}