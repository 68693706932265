import React, { useContext, useState, useEffect } from 'react'
import * as groupQuery from 'utility/queries/group'
import * as invoiceQuery from 'utility/queries/invoice'
import { defaultObjData, defaultArrayData } from 'utility/useStateDefaults'

const GroupContext = React.createContext(null)

const GroupProvider = ({ children }) => {
	const [group, setGroup] = useState(defaultObjData)
	const [paymentMethod, setPaymentMethod] = useState(defaultObjData)
	const [invoices, setInvoices] = useState(defaultArrayData)
	const [billingHistory, setBillingHistory] = useState(defaultArrayData)

	const fetchGroup = async ({ id, year }) => {
		setGroup({ ...group, error: '', loading: true })
		try {
			const groupData = await groupQuery.fetchGroupById({ id, year })

			setGroup({
				...group,
				data: groupData,
				loading: false,
			})
		} catch (err) {
			setGroup({
				...group,
				error: 'Trouble loading group',
				loading: false,
			})
		}
	}

	const fetchBillingHistory = async ({ id }) => {
		setBillingHistory({ ...billingHistory, error: '', loading: true })
		try {
			const billingHistoryData = await invoiceQuery.fetchInvoiceHistory({
				groupId: id,
			})

			setBillingHistory({
				...billingHistory,
				data: billingHistoryData,
				loading: false,
			})
		} catch (err) {
			setBillingHistory({
				...billingHistory,
				error: 'Trouble loading invoices',
				loading: false,
			})
		}
	}

	const fetchInvoices = async ({ id, year }) => {
		setInvoices({ ...invoices, error: '', loading: true })
		try {
			const invoiceData = await invoiceQuery.fetchInvoicesFromGroup({
				groupId: id,
				year,
			})
			setInvoices({ ...invoices, data: invoiceData, loading: false })
		} catch (err) {
			setInvoices({
				...invoices,
				error: 'Trouble loading invoices',
				loading: false,
			})
		}
	}

	const payInvoice = async ({ invoiceId, invoiceAmount, paymentMethod }) => {
		return await invoiceQuery.payInvoice({
			invoiceAmount,
			invoiceId,
			paymentMethod,
		})
	}

	const payAchInvoice = async ({ invoiceId, invoiceAmount, paymentMethod }) => {
		return await invoiceQuery.payAchInvoice({
			invoiceAmount,
			invoiceId,
			paymentMethod,
		})
	}

	const payRenewal = async ({ groupId, amountInCents, paymentMethodId }) => {
		return invoiceQuery.payRenewal({
			amountInCents,
			groupId,
			paymentMethodId,
		})
	}

	const updateGroupDefaultPaymentMethod = async ({ id, paymentMethod }) => {
		return groupQuery.updateDefaultPayment({ id, paymentMethod })
	}

	const fetchGroupPaymentMethod = async ({ id }) => {
		setPaymentMethod({ ...paymentMethod, error: '', loading: true })
		try {
			const results = await groupQuery.getGroupPaymentMethods({ id })
			setPaymentMethod({ ...paymentMethod, data: results, loading: false })
		} catch (err) {
			setPaymentMethod({
				...paymentMethod,
				error: 'Trouble loading paymentMethods',
				loading: false,
			})
		}
	}

	return (
		<GroupContext.Provider
			value={{
				businessNameKeywords: ['LLC', 'Trust', 'Inc', 'Series'],

				fetchGroup,
				fetchBillingHistory,
				fetchInvoices,
				payInvoice,
				payAchInvoice,
				payRenewal,

				paymentMethod,
				updateGroupDefaultPaymentMethod,
				fetchGroupPaymentMethod,

				group,
				billingHistory,
				invoices,
			}}
		>
			{children}
		</GroupContext.Provider>
	)
}

const useGroup = () => {
	const auth = useContext(GroupContext)
	if (auth == null) {
		throw new Error('useGroup() called outside of a GroupProvider?')
	}
	return auth
}

export { GroupProvider, useGroup }
