import React from 'react'
import styled from 'styled-components'
import CardTitle from 'components/common/cards/CardTitle'
import Subtitle from 'components/common/Subtitle'

const StyledCard = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  background-color: ${({ background = '#fff' }) => background};
  padding: ${({ noPadding }) => (noPadding ? '0px' : '20px')};
  border: ${({ outline }) => (outline ? '1px solid #e6e6e6' : 'none')};
  border-radius: 10px;
  box-shadow: ${(props) =>
    props.noShadow ? 'none' : '0 2px 10px 0 rgba(44, 44, 44, .1)'};
`

const Card = ({
  children,
  noShadow = false,
  noPadding = false,
  outline = false,
  style,
  title,
  subtitle,
  titleAlign = 'left',
  background = '#fff',
  subtitleAlign = 'left',
  titleStyle = {},
  ...props
}) => {
  return (
    <StyledCard
      noShadow={noShadow}
      noPadding={noPadding}
      background={background}
      outline={outline}
      style={style}
      {...props}
    >
      {title ? (
        <CardTitle
          align={titleAlign}
          style={{ marginBottom: 20, ...titleStyle }}
        >
          {title}
        </CardTitle>
      ) : null}
      {subtitle ? <Subtitle align={subtitleAlign}>{subtitle}</Subtitle> : null}
      {children}
    </StyledCard>
  )
}

export default Card
