import axios from 'utility/wpAxios'
import { constructWPURL } from 'utility/query'

export const fetchPages = async () => {
  try {
    let _query = {
      page: 1,
      per_page: 20,
      embed: true,
    }
    let requestUrl = constructWPURL('/pages', _query)
    const res = await axios.get(requestUrl)
    const _pages = res.data
    return _pages
  } catch (err) {
    console.log('Error: ', err)
  }
}

export const fetchPageBySlug = async (slug) => {
  try {
    let _query = {
      embed: true,
      slug,
    }

    let requestUrl = constructWPURL('/pages', _query)
    const res = await axios.get(requestUrl)
    const pages = res.data
    let _foundage

    if (Array.isArray(pages) && pages.length > 0) {
      _foundage = pages[0]
    }

    return _foundage
  } catch (err) {
    console.log('Error: ', err)
  }
}

export const fetchPosts = async () => {
  try {
    let _query = {
      page: 1,
      per_page: 12,
      embed: true,
    }
    let requestUrl = constructWPURL('/posts', _query)
    const res = await axios.get(requestUrl)
    const _posts = res.data

    return _posts
  } catch (err) {
    console.log('Error: ', err)
  }
}

export const fetchPostBySlug = async (slug) => {
  try {
    let _query = {
      embed: true,
      slug,
    }

    let requestUrl = constructWPURL('/posts', _query)
    const res = await axios.get(requestUrl)
    const _posts = res.data
    let _foundPost

    if (Array.isArray(_posts) && _posts.length > 0) {
      _foundPost = _posts[0]
    }

    return _foundPost
  } catch (err) {
    console.log('Error: ', err)
  }
}

export const fetchCategories = async () => {
  try {
    let _query = {
      page: 1,
      per_page: 100,
      embed: true,
    }
    let requestUrl = constructWPURL('/categories', _query)
    const res = await axios.get(requestUrl)
    const _categories = res.data
    return _categories
  } catch (err) {
    console.log('Error: ', err)
  }
}

export const fetchQuestions = async () => {
  try {
    let _query = {
      page: 1,
      per_page: 100,
    }
    let requestUrl = constructWPURL('/questions', _query)
    const res = await axios.get(requestUrl)
    const _questions = res.data

    return _questions
  } catch (err) {
    console.log('Error: ', err)
  }
}

export const fetchTestimonials = async () => {
  try {
    let _query = {
      page: 1,
      per_page: 100,
    }
    let requestUrl = constructWPURL('/testimonials', _query)
    const res = await axios.get(requestUrl)
    const _testimonials = res.data

    return _testimonials
  } catch (err) {
    console.log('Error: ', err)
  }
}

export const fetchTestimonialBySlug = async (slug) => {
  try {
    let _query = {
      embed: true,
      slug,
    }

    let requestUrl = constructWPURL('/testimonials', _query)
    const res = await axios.get(requestUrl)
    const _testimonial = res.data
    let _foundTestimonial

    if (Array.isArray(_testimonial) && _testimonial.length > 0) {
      _foundTestimonial = _testimonial[0]
    }

    return _foundTestimonial
  } catch (err) {
    console.log('Error: ', err)
  }
}

export const fetchTestimonialCategories = async () => {
  try {
    let _query = {
      page: 1,
      per_page: 100,
      embed: true,
    }
    let requestUrl = constructWPURL('/testimonial_category', _query)
    const res = await axios.get(requestUrl)
    const _categories = res.data
    return _categories
  } catch (err) {
    console.log('Error: ', err)
  }
}

export const fetchCountyBySlug = async (slug) => {
  try {
    let _query = {
      embed: true,
      slug,
    }

    let requestUrl = constructWPURL('/counties', _query)
    const res = await axios.get(requestUrl)
    const _counties = res.data
    let _foundCounty

    if (Array.isArray(_counties) && _counties.length > 0) {
      _foundCounty = _counties[0]
    }

    return _foundCounty
  } catch (err) {
    console.log('Error: ', err)
  }
}

export const fetchContentCalendar = async () => {
  try {
    let _query = {
      page: 1,
      per_page: 100,
    }
    let requestUrl = constructWPURL('/content_calendar', _query)
    const res = await axios.get(requestUrl)
    const _contentCalendar = res.data

    const now = new Date().getTime()
    const modifiedContentCalendar = _contentCalendar.map((item) => {
      const start = new Date(item.acf.meta.default_start_date)
      return {
        ...item,
        dates: {
          // start,
          timeFromToday: now - start.getTime(),
        },
      }
    })

    const sortedContentCalendar = modifiedContentCalendar.sort((a, b) => {
      const dateA = new Date(a.dates.timeFromToday)
      const dateB = new Date(b.dates.timeFromToday)
      return dateB - dateA
    })

    return sortedContentCalendar
  } catch (err) {
    console.log('Error: ', err)
  }
}

export const fetchPartnerPosts = async () => {
  try {
    let _query = {
      page: 1,
      per_page: 100,
    }
    let requestUrl = constructWPURL('/partner_post', _query)
    const res = await axios.get(requestUrl)
    return res.data
  } catch (err) {
    console.log('Error: ', err)
  }
}

export const fetchPartnerPostTopics = async () => {
  try {
    let _query = {
      page: 1,
      per_page: 100,
    }
    let requestUrl = constructWPURL('/partner_post_topic', _query)
    const res = await axios.get(requestUrl)
    return res.data
  } catch (err) {
    console.log('Error: ', err)
  }
}
