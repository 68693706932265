/*
  Used to check the app environment & set any necessary .env variables
*/

export const production = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
export const review = process.env.NEXT_PUBLIC_ENVIRONMENT === 'review'
export const development = !production && !review
export const readable = production ? 'production' : review ? 'review' : 'development'

export default {
  readable,
  production,
  review,
  development
}