import React, { useEffect, useState } from 'react'
import Router, { useRouter } from 'next/router'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { library } from '@fortawesome/fontawesome-svg-core'
import { ErrorBoundary } from 'components/common/ErrorBoundary'
import { GoogleAnalytics } from 'nextjs-google-analytics'
import theme from 'styles/theme'
import { StateProvider } from 'state'
import { mainReducer, initialState } from 'state/reducers'
import { TenantProvider } from 'components/providers/TenantProvider'
import { BlogProvider } from 'components/providers/BlogProvider'
import { GroupProvider } from 'components/providers/GroupProvider'
import { PartnerProvider } from 'components/providers/PartnerProvider'
import { SelfProvider } from 'components/providers/SelfProvider'
import { AppProvider } from 'components/providers/AppProvider'
import GlobalLoading from 'components/common/GlobalLoading'
import isBrowser from 'utility/isBrowser'
import { COLORS } from 'utility/colors'
import environment from 'utility/environment'
import isProductionAndBrowser from 'utility/isProductionAndBrowser'
import NProgress from 'nprogress' //nprogress module
import 'nprogress/nprogress.css' //styles of nprogress
import * as google from 'utility/google'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

import {
	faFilePdf,
	faPen,
	faBell,
	faUndo,
	faUsers,
	faMegaphone,
	faFileDownload,
	faNetworkWired,
	faThumbsUp,
	faCreditCardFront,
	faHomeHeart,
	faEye,
	faFolder,
	faHandshakeAlt,
	faMapMarkerAlt,
	faQuoteRight,
	faQuoteLeft,
	faCheck,
	faTimes as faSolidTimes,
	faTimesCircle,
	faUserTie,
	faEdit,
	faHome,
	faHandshake,
	faLightbulb,
	faChartLine,
	faDollarSign,
	faChartNetwork,
	faMapPin,
	faEnvelope as fabEnvelope,
	faPhone as fabPhone,
	faChevronLeft as fabChevronLeft,
	faChevronRight as fasChevronRight,
	faChevronUp,
	faChevronDown,
	faCaretRight,
	faClipboardCheck,
	faSackDollar,
	faMagic,
	faUser,
	faClock,
	faBadgeDollar,
	faUserShield,
	faImage,
	faExclamationCircle as faSolidExclamationCircle,
	faList,
	faFileInvoiceDollar as faFileInvoiceDollarSolid,
	faLayerGroup,
	faGraduationCap,
	faGlobe as faGlobeSolid,
	faBrowser,
	faTable,
	faIdCard,
	faFarm,
	faFileCheck
} from '@fortawesome/pro-solid-svg-icons'
import {
	faExclamation,
	faDotCircle,
	faInfoCircle,
	faLink,
	faEnvelope,
	faGlobe,
	faPhone,
	faHeart,
	faSearchLocation,
	faPlus,
	faChevronLeft,
	faCommentExclamation,
	faQuestion,
	faExclamationCircle,
	faCalendar,
	faArrowRight,
	faStopwatch,
	faTag,
	faCloudUpload,
	faSunglasses,
	faExternalLink,
	faChevronRight,
	faTrash,
	faHome as faRegularHome,
	faIdCard as faRegularIdCard,
	faFarm as faRegularFarm,
} from '@fortawesome/pro-regular-svg-icons'
import {
	faBars,
	faTimes,
	faHammer,
	faFileContract,
	faFileInvoiceDollar,
	faCamera,
} from '@fortawesome/pro-light-svg-icons'

google.init()

// Initialize logrocket
import { init, integrateWithGa, integrateWithCrisp } from 'utility/logrocket'
init()
integrateWithGa()
integrateWithCrisp()

import '@progress/kendo-theme-bootstrap/dist/all.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import 'doka/doka.css'

// import './App.css';

library.add(
	faFileCheck,
	faFilePdf,
	faPen,
	faEye,
	faTrash,
	faRegularHome,
	faRegularIdCard,
	faRegularFarm,
	faHome,
	faIdCard,
	faFarm,
	faLayerGroup,
	faSolidExclamationCircle,
	faBell,
	faUndo,
	faUsers,
	faFileInvoiceDollarSolid,
	faMegaphone,
	faList,
	faImage,
	faExternalLink,
	faFileDownload,
	faCloudUpload,
	faHammer,
	faFileContract,
	faFileInvoiceDollar,
	faCamera,
	faExclamation,
	faStopwatch,
	faTag,
	faPlus,
	faSunglasses,
	faNetworkWired,
	faThumbsUp,
	faCreditCardFront,
	faHomeHeart,
	faFolder,
	faHandshakeAlt,
	faMapMarkerAlt,
	faDotCircle,
	faQuoteRight,
	faQuoteLeft,
	faSolidTimes,
	faCheck,
	faTimesCircle,
	faUserTie,
	faHome,
	faEdit,
	faInfoCircle,
	faHandshake,
	faLightbulb,
	faChartLine,
	faDollarSign,
	faChartNetwork,
	fabPhone,
	fabEnvelope,
	faMapPin,
	fabChevronLeft,
	faChevronLeft,
	faChevronRight,
	fasChevronRight,
	faClipboardCheck,
	faCaretRight,
	faSackDollar,
	faMagic,
	faUser,
	faClock,
	faBadgeDollar,
	faUserShield,
	faLink,
	faEnvelope,
	faGlobe,
	faGlobeSolid,
	faBrowser,
	faPhone,
	faHeart,
	faSearchLocation,
	faCommentExclamation,
	faQuestion,
	faChevronUp,
	faChevronDown,
	faExclamationCircle,
	faCalendar,
	faArrowRight,
	faBars,
	faTimes,
	faGraduationCap,
	faTable,
)

// This resets the base styles set by the browser
const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  main, menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: 'Lato', 'Poppins', sans-serif;
    vertical-align: baseline;
  }
  p{
    text-overflow: ellipsis;
    overflow: hidden;
  }
  a{
    color: inherit;
  }
  a:hover{
    color: inherit;
    text-decoration: none;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, main, menu, nav, section {
    display: block;
  }
  /* HTML5 hidden-attribute fix for newer browsers */
  *[hidden] {
    display: none;
  }
  body {
    /* background-color: ${COLORS.BACKGROUND}; */
    line-height: 1;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100% !important;
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    overflow-x: hidden;
  }
  html{
    height: -webkit-fill-available;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .modal-content {
    /* max-height: 100vh; */
    overflow: hidden;
  }

  input{
    border:1px solid #cccccc;
  }
  
  input:focus { 
    outline: none !important;
    border: 1px solid ${COLORS.ACCENT};
    box-shadow: 0 0 10px #dddddd;

    /* outline-color: #acd9dd !important; */
  }
  button:focus { 
    outline: none;
  }

  .rbt-input.focus{
    outline: none !important;
    border: 1px solid ${COLORS.ACCENT} !important;
    box-shadow: 0 0 10px #dddddd !important;
  }
  .App {
    text-align: center;
    background-color: #fbf7f5;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  .main-content {
    margin: 25px;
    padding: 25px;
    background: #f9f1f1;
    border-radius: 10px;
  }

  .rccs {
    margin: inherit;
    /* width: 150px; */
    /* height: 95px; */
  }

  /* Kendo UI */
  .k-editor{
    border-radius: 5px !important;
  }
  .k-toolbar{
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
  }

  .name-column {
    text-align: left;
  }

  .description-row {
    text-align: left;
    padding-top: 15px;
    padding-bottom: 5px;
  }

  .details-row {
    border-top: 1px gray solid;
  }

  .second-row {
    padding-top: 15px;
  }

  .maintenance-row {
    border-top: 1px gray solid;
  }

  .parameters-row {
    border-top: 1px gray solid;
  }

  .section-header-col {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .toolTip{
    width: initial;
    max-width: 300px;
    opacity: 1 !important;
    background-color: white;
    z-index: 99999 !important;
  }
  .toolTipModal p{
    /* white-space: nowrap; */
  }
  .modal-dialog{
    width: 100%;
  }

  .map-container > div:first-of-type{
    width: 100%;
    height: 100%;
  }
  .doka-image-editor {
    --color-background: 237, 237, 237;
    --color-foreground: 0, 0, 0;
  }
  .__react_component_tooltip{
    background-color: #F9F9F9 !important;
  }
  @media (max-width: 768px) {
    .toolTip{
      width: 50%;
    }
  }
`

// replace console.* for disable log on production
if (environment.production) {
	console.log = () => {}
	console.debug = () => {}
}

const App = ({ Component, pageProps }) => {
	// Not currently using, but this will allow us to manage the loading components here.
	const [loading, setLoading] = useState(false)
	const router = useRouter()

	useEffect(() => {
		const startUrl = router.asPath // isBrowser && window.window.location.href
		google && google.pageview(startUrl)
		//Binding events.
		Router.events.on('routeChangeStart', () => {
			setLoading(true)
			NProgress.start()
		})
		Router.events.on('routeChangeComplete', (url, { shallow }) => {
			isBrowser && !shallow && window.scrollTo(0, 0)
			setLoading(false)
			NProgress.done()
			google && google.pageview(url)
		})
		Router.events.on('routeChangeError', () => {
			setLoading(false)
			NProgress.done()
		})
		// Include the Crisp code here, without the <script></script> tags
		if (isProductionAndBrowser) {
			window.$crisp = []
			window.CRISP_WEBSITE_ID = '3469a4a0-0b02-40b9-ada0-e147e2aa6530'
			;(function () {
				var d = document
				var s = d.createElement('script')

				s.src = 'https://client.crisp.chat/l.js'
				s.async = 1
				d.getElementsByTagName('head')[0].appendChild(s)
			})()
		}
	}, [])

	// return (
	//   <div>
	//     <h1>Undergoing site maintenence</h1>
	//     <p>You're on HomeTaxShield.com and we'll be back shortly.</p>
	//   </div>
	// )

	return (
		<ErrorBoundary>
			<>
				{/* <!-- Google Tag Manager (noscript) --> */}
				<noscript>
					<iframe
						src='https://www.googletagmanager.com/ns.html?id=GTM-TGJD4TB'
						height='0'
						width='0'
						style={{ display: 'none', visibility: 'hidden' }}
					></iframe>
				</noscript>
				{/* <!-- End Google Tag Manager (noscript) --> */}
				<ToastContainer theme='light' />
				<GoogleAnalytics
					trackPageViews
					debugMode={environment.production ? false : true}
					gaMeasurementId={google.GA4_TRACKING_ID}
				/>
				<QueryClientProvider client={queryClient}>
					<ThemeProvider theme={theme}>
						<StateProvider reducer={mainReducer} initialState={initialState}>
							<AppProvider>
								<TenantProvider>
									<SelfProvider>
										<BlogProvider>
											<GroupProvider>
												<PartnerProvider>
													<Component {...pageProps} />
													<GlobalStyle />
													<GlobalLoading />
												</PartnerProvider>
											</GroupProvider>
										</BlogProvider>
									</SelfProvider>
								</TenantProvider>
							</AppProvider>
						</StateProvider>
					</ThemeProvider>
				</QueryClientProvider>
			</>
		</ErrorBoundary>
	)
}

export function reportWebVitals({ id, name, label, value }) {
	google.event({
		action: name,
		category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
		value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
		label: id, // id unique to current page load
		nonInteraction: true, // avoids affecting bounce rate.
	})
}

export default App
