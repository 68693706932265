// shared theme used in sytled-components
// fonts added via google fonts in ../components/Fonts.js

// bootstrap breakpoints
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

const theme = {
  breakpoints,
  color: {
    primary: '#0070f3',
    lightGray: '#F4F5F6',
    lightBlue: '#F4F7F9',
    blue: '#0078C8',
    shadow: '#D6DAE1',
  },
  fonts: {
    primary: "'Poppins', sans-serif",
  },
};

export default theme