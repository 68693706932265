import { types } from './propertyActions';

export const propertyState = {}

const propertyReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.SET_PROPERTY:
      return payload.property;

    default:
        return {...state };
  }
}

export default propertyReducer