import * as logrocket from 'utility/logrocket';
import * as crisp from 'utility/crisp';
import environment from 'utility/environment';

const verboseEnabled = false

class Logger {
  logFactory(level, ...logArgs){
    switch (level) {
    case 'error':
      console.error(...logArgs)
      break
    case 'warning':
      console.warn(...logArgs)
      break
    case 'info':
      console.info(...logArgs)
      break
    case 'log':
    default:
      console.log(...logArgs)
      break
    }
  }

  log(first='', second, data, level = 'log'){
    const seperator = '->'
    const lineChar = '='
    const lineLength = 40
    const lineEnd = '\n'
    const line = new Array(lineLength).fill(lineChar).join('') + lineEnd
    let logString = first

    if(second)
      logString += ` ${seperator} ` + second

    logString = lineEnd + line + logString + lineEnd + line
    let logArgs = [logString]

    if(data)
      logArgs.push(data)

    this.logFactory(level, ...logArgs)
  }
  
  error(first, data, level = 'error'){
    if(!first) return null
    
    this.log(first, null, data, level)
    const error = {log: new Error(`${first}`), err: data}

    // Track errors on various services
    crisp.customEvent(first)
    logrocket.track(first)
    logrocket.captureException({data, extra: {name: first}})
  }
  warn(first, data, level = 'warn'){
    this.log(first, null, data, level)

    const message = {log: `${first} => ${data}`}
  }
  default(first, data, body){
    if(environment.production)
      return null

    this.log(first, data, body)
  }
  verbose(first, data, body){
    if(environment.production)
      return null

    if(verboseEnabled)
      this.log(first, data, body)
  }
}

export default new Logger()