const getNested = (object, ...props) => {
  if(!object)
    return object;
  else if(props.length === 0 || !props)
    return object
  else {
    const prop = props[0];
    const rest = props.slice(1)
    const nextParent = object[prop]

    // Recursively call self, until props found, or parent is undefined
    return getNested(nextParent, ...rest)
  }
}

export default getNested;