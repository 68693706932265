import axios from 'utility/axios';
import getNested from 'utility/getNested';

export const types = {
  SET_PROPERTIES: 'SET_PROPERTIES',
};

export const getProperties = async (dispatch) => {
  try{
    const url = 'http://localhost:3001/properties';
    const response = await axios.get(url);
    const properties = getNested(response, 'data', 'properties')

    dispatch({
      type: types.SET_PROPERTIES,
      payload: {
        properties,
      },
    });
  }catch(err){
    console.log("Error getting properties", err)
  }
}
