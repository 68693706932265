import React, { useContext, useState, useEffect } from 'react'
import * as partnerQuery from 'utility/queries/partner'
import { defaultObjData } from 'utility/useStateDefaults'
import { useSelf } from './SelfProvider'

const PartnerContext = React.createContext(null)

const PartnerProvider = ({ children }) => {
  const { isSignedIn } = useSelf()

  const [partner, setPartner] = useState(defaultObjData)
  const [partnerProperties, setPartnerProperties] = useState(defaultObjData)
  const [partnerStats, setPartnerStats] = useState(defaultObjData)

  useEffect(() => {
    if (!isSignedIn) {
      setPartner(defaultObjData)
      setPartnerStats(defaultObjData)
    }
  }, [isSignedIn])

  const fetchPartner = async ({ slug, id }) => {
    setPartner({ ...partner, error: '', loading: true })
    try {
      const publicPartnerData = await partnerQuery.fetchPublicPartner({ slug })
      const partnerData = await partnerQuery
        .fetchPartner({
          slug,
          id,
        })
        .catch((err) => {
          console.log('FetchPartner Error: ', err)
        })
      setPartner({
        ...partner,
        data: {
          ...publicPartnerData,
          ...partnerData,
        },
        loading: false,
      })
    } catch (err) {
      setPartner({
        ...partner,
        error: 'Trouble loading partner',
        loading: false,
      })
    }
  }

  const fetchPartnerProperties = async ({ partnerId, year }) => {
    setPartnerProperties({ ...partnerProperties, error: '', loading: true })
    try {
      const partnerPropertiesData =
        await partnerQuery.fetchPartnerPropertiesByPartnerId({
          partnerId,
          year,
        })
      setPartnerProperties({
        ...partnerProperties,
        data: partnerPropertiesData,
        loading: false,
      })
    } catch (err) {
      setPartnerProperties({
        ...partnerProperties,
        error: 'Trouble loading partner properties',
        loading: false,
      })
    }
  }

  const fetchPartnerStats = async ({ partnerId, year }) => {
    setPartnerStats({ ...partnerStats, error: '', loading: true })

    try {
      const partnerStatsData = await partnerQuery.fetchPartnerStatsByPartnerId({
        partnerId,
        year,
      })
      setPartnerStats({
        ...partnerStats,
        data: partnerStatsData,
        loading: false,
      })
    } catch (err) {
      setPartnerStats({
        ...partnerStats,
        error: 'Trouble loading partner stats',
        loading: false,
      })
    }
  }

  return (
    <PartnerContext.Provider
      value={{
        fetchPartner,
        partner,

        fetchPartnerProperties,
        partnerProperties,

        fetchPartnerStats,
        partnerStats,
      }}
    >
      {children}
    </PartnerContext.Provider>
  )
}

const usePartner = () => {
  const auth = useContext(PartnerContext)
  if (auth == null) {
    throw new Error('usePartner() called outside of a PartnerProvider?')
  }
  return auth
}

export { PartnerProvider, usePartner }
