const breakpoints = (width) => {
  let widthObj = {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  }

  if(width >=0)
    widthObj.xs = true

  if(width >= 576)
    widthObj.sm = true;
  
  if(width >= 768)
    widthObj.md = true;
  
  if(width >= 992)
    widthObj.lg = true;

  if(width >= 1200)
    widthObj.xl = true;

  return widthObj
}

export default breakpoints;